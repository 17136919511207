<template>
  <div class="search-sidebar--dropdown mb-4">
    <h3 class="filter--title">{{ $t('general.filter.propertyType') }}</h3>
    <property-type-dropdown></property-type-dropdown>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SearchMixin from '@/mixins/searchMixin';
import PropertyTypeDropdown from '@/components/search/partials/property-type-dropdown';

export default {
  components: { PropertyTypeDropdown },
  mixins: [SearchMixin],
  data: () => ({
    isOpened: true,
    fromPage: 'SEARCH',
    isMobileFilter: false,
  }),
  computed: {
    ...mapState({
      isLoading: (state) => state.v2.search.isLoading,
    }),
  },
  created() {},
  methods: {},
};
</script>
