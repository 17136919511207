<template>
  <div class="search-sidebar--dropdown mb-4">
    <h3 class="filter--title">{{ $t('general.city') }}</h3>
    <multiselect
      id="city-id-field"
      name="city"
      v-model="selectedCities"
      :options="cities"
      select-label=""
      deselect-label=""
      track-by="id"
      label="name"
      :searchable="true"
      :show-labels="true"
      :placeholder="$t('general.filter.allCities')"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      openDirection="bottom"
      @close="applyFilters"
      @remove="onRemove"
    >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
import { mapState } from 'vuex';
import SearchMixin from '@/mixins/searchMixin';
import LodashMixin from '@/mixins/lodash';

export default {
  components: { Multiselect },
  mixins: [SearchMixin, LodashMixin],
  data: () => ({
    isOpened: true,
    fromPage: 'SEARCH',
    isMobileFilter: false,
  }),
  computed: {
    ...mapState({
      isLoading: (state) => state.v2.search.isLoading,
      cities: (state) => state.v2.masters.cities,
    }),
    selectedCities: {
      get() {
        return this.$store.state.v2.search.selectedCities;
      },
      set(val) {
        this.$store.commit('v2/search/SET_SELECTED_CITIES', val);
      },
    },
  },
  mounted() {
    this.debouncedRemove = this.debounce(this.applyFilters, 1000);
  },
  methods: {
    onRemove() {
      this.debouncedRemove();
    },
    applyFilters() {
      if (this.$mq !== 'xs') {
        this.$store.dispatch('v2/search/goToSearchPage', {
          router: this.$router,
          params: this.$route.params,
        });
      }
    },
  },
};
</script>
