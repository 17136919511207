<template>
  <section class="search--header" v-if="!isOfficialPartnerArea">
    <div class="d-flex flex-wrap align-items-start" v-if="$mq !== 'xs'">
      <search-listing />
      <div class="dropdown--wrapper" v-if="$mq !== 'xs'">
        <property-type-dropdown :applyFilterFunction="forceSearch"></property-type-dropdown>
      </div>
      <div class="search--button" @click="forceSearch">
        <img src="@/assets/img/icons/search.svg" alt="Cari" />
      </div>
    </div>
    <!--    <client-only>-->
    <!--      <div class="search&#45;&#45;header-content">-->
    <!--      <div class="dropdown&#45;&#45;wrapper">-->
    <!--          <v-select-->
    <!--            :value="listingType"-->
    <!--            @input="inputListingTypeHandler"-->
    <!--            :items="listingTypes"-->
    <!--            :hint="`${$t('addListing.listingType')} ${$t(`general.filter.${listingType}`)}`"-->
    <!--            class="basic&#45;&#45;dropdown"-->
    <!--            persistent-hint-->
    <!--            single-line-->
    <!--          >-->
    <!--            <template slot="selection" slot-scope="{ item }">-->
    <!--              {{ $t(`general.filter.${item}`) }}-->
    <!--            </template>-->
    <!--            <template slot="item" slot-scope="{ item }">-->
    <!--              {{ $t(`general.filter.${item}`) }}-->
    <!--            </template>-->
    <!--          </v-select>-->
    <!--      </div>-->
    <!--        <div class="listing&#45;&#45;type-tab">-->
    <!--          <v-tabs-->
    <!--            center-active-->
    <!--            show-arrows-->
    <!--            v-model="activeListingTypeTab"-->
    <!--            v-if="listingTypes && listingTypes.length > 0"-->
    <!--          >-->
    <!--            <v-tab-->
    <!--              :href="`#tab-listing-type-${listingType}`"-->
    <!--              v-for="listingType in listingTypes"-->
    <!--              :key="`listing-type-${listingType}`"-->
    <!--              @click="inputListingTypeHandler(listingType)"-->
    <!--            >-->
    <!--              <div class="d-flex justify-center">-->
    <!--                <img src="@/assets/img/icons/paper.png" v-if="listingType === 'R'" />-->
    <!--                <img src="@/assets/img/icons/tag.png" v-else />-->
    <!--                {{ $t(`general.filter.${listingType}`) }}-->
    <!--              </div>-->
    <!--            </v-tab>-->
    <!--          </v-tabs>-->
    <!--        </div>-->
    <!--      <div class="dropdown&#45;&#45;wrapper">-->
    <!--          <v-select-->
    <!--            :value="activePropertyType"-->
    <!--            @input="inputPropertyTypeHandler"-->
    <!--            :items="propertyTypes"-->
    <!--            :item-text="propertyTypeLabel"-->
    <!--            item-value="id"-->
    <!--            :hint="`${$t('general.propertyType')}`"-->
    <!--            class="basic&#45;&#45;dropdown"-->
    <!--            persistent-hint-->
    <!--            single-line-->
    <!--          ></v-select>-->
    <!--      </div>-->
    <!--        <div class="property&#45;&#45;type-tab">-->
    <!--          <v-tabs-->
    <!--            center-active-->
    <!--            show-arrows-->
    <!--            v-model="activePropertyTypeTab"-->
    <!--            v-if="propertyTypes && propertyTypes.length > 0"-->
    <!--          >-->
    <!--            <v-tab-->
    <!--              :href="`#tab-property-type-${propertyType.id}`"-->
    <!--              v-for="propertyType in propertyTypes"-->
    <!--              :key="`property-type-${propertyType.id}`"-->
    <!--              @click="inputPropertyTypeHandler(propertyType.id)"-->
    <!--            >-->
    <!--              {{ propertyTypeLabel(propertyType) }}-->
    <!--            </v-tab>-->
    <!--          </v-tabs>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </client-only>-->
  </section>
</template>

<script>
import SearchListing from '@/components/search/partials/search-listing';
import PropertyTypeDropdown from '@/components/search/partials/property-type-dropdown';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'search-header',
  components: { SearchListing, PropertyTypeDropdown },
  data: () => ({
    activeListingTypeTab: 0,
    activePropertyTypeTab: 0,
  }),
  computed: {
    ...mapState({
      propertyTypeId: (state) => state.v2.search.propertyTypeId,
      listingType: (state) => state.v2.search.listingType,
      listingTypes: (state) => state.global.listingTypes,
      isOfficialPartner: (state) => state.v2.search.isOfficialPartner,
      propertyTypes: (state) => state.v2.masters.propertyTypes,
    }),
    ...mapGetters({
      // propertyTypes: 'v2/masters/propertyTypesSearch',
    }),
    isOfficialPartnerArea() {
      return this.$route.params.areaUrl != null;
    },
  },
  watch: {
    async listingType(value) {
      this.calculateListingTypeTab(value);
    },
    propertyTypeId(value) {
      this.calculatePropertyTypeTab(value);
    },
  },
  mounted() {
    this.calculateListingTypeTab(this.listingType);
    this.calculatePropertyTypeTab(this.propertyTypeId);
  },
  methods: {
    propertyTypeLabel(value) {
      return value.name === 'general.all' ? this.$i18n.t(`${value.name}`) : value.name;
    },
    inputListingTypeHandler(value) {
      this.$store.commit('v2/search/SET_LISTING_TYPE', value);
      this.$store.commit('v2/search/SET_PAGE', 1);
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
    inputPropertyTypeHandler(value) {
      this.$store.commit('v2/search/SET_PROPERTY_TYPE_ID', value);
      this.$store.commit('v2/search/SET_PAGE', 1);
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
    inputFilterHandler() {
      this.$store.commit('v2/search/SET_PAGE', 1);
    },
    calculateListingTypeTab(value) {
      this.activeListingTypeTab = 'tab-listing-type-' + value;
    },
    calculatePropertyTypeTab(value) {
      this.activePropertyTypeTab = 'tab-property-type-' + value;
    },
    async forceSearch() {
      this.$store
        .dispatch('v2/search/goToSearchPage', {
          router: this.$router,
          params: this.$route.params,
        })
        // Catch error route karena routing ke url yang sama
        .catch(async () => {
          console.log('SAME ROUTE, FORCING SEARCH!');
          this.$store.commit('global/SET_LOADING', true);
          if (!this.isOfficialPartner) {
            await this.$store.dispatch('v2/search/getSearchListing', {
              query: this.$route.query,
              params: this.$route.params,
            });
          } else {
            await this.$store.dispatch('v2/search/getOfficialPartnerAreas', {
              query: this.$route.query,
              params: this.$route.params,
            });
          }
          this.$store.commit('global/SET_LOADING', false);
        });
    },
  },
};
</script>
