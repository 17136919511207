<template>
  <div class="search-sidebar--dropdown">
    <div class="d-flex" style="justify-content: space-between" @click="open">
      <h3 class="filter--title">{{ $t('general.filter.duration') }}</h3>
      <i :class="!isOpened ? 'ion-chevron-down' : 'ion-chevron-up'"></i>
    </div>
    <div v-show="isOpened">
      <div class="dropdown-item">
        <div class="custom-control custom-radio">
          <input
            type="radio"
            id="rentPeriodAll"
            v-model="rentPeriodId"
            :value="null"
            class="custom-control-input"
            :disabled="isLoading"
          />
          <label class="custom-control-label" for="rentPeriodAll">{{ $t('general.all') }}</label>
        </div>
      </div>
      <div class="dropdown-item" v-for="(rentPeriod, key) in rentPeriods" :key="key">
        <div class="custom-control custom-radio">
          <input
            type="radio"
            :id="'rentPeriod' + key"
            v-model="rentPeriodId"
            :value="rentPeriod.id"
            class="custom-control-input"
            :disabled="isLoading"
          />
          <label class="custom-control-label" :for="'rentPeriod' + key">{{
            rentPeriod.name
          }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SearchMixin from '@/mixins/searchMixin';

export default {
  mixins: [SearchMixin],
  data: () => ({
    isOpened: true,
    fromPage: 'SEARCH',
    isMobileFilter: false,
  }),
  computed: {
    ...mapState({
      rentPeriods: (state) => state.v2.masters.rentPeriods,
      isLoading: (state) => state.v2.search.isLoading,
    }),
    rentPeriodId: {
      get() {
        return this.rentPeriod ? this.rentPeriod.id : null;
      },
      set(val) {
        if (val) {
          let rentPeriod = this.rentPeriods.find((elem) => {
            return elem.id === val;
          });
          this.setRentPeriod(rentPeriod.id, rentPeriod.text);
        } else {
          this.setRentPeriod(null, null);
        }
      },
    },
  },
  methods: {
    async setRentPeriod(id, text) {
      this.rentPeriod = {
        id: id,
        name: text,
      };
    },
    open() {
      this.isOpened = !this.isOpened;
    },
    onUpdateFilter() {},
  },
};
</script>
