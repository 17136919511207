<template>
  <div class="search-sidebar--dropdown">
    <div class="d-flex" style="justify-content: space-between" @click="open">
      <h3 class="filter--title">{{ $t('general.filter.filter') }}</h3>
      <i :class="!isOpened ? 'ion-chevron-down' : 'ion-chevron-up'"></i>
    </div>
    <div v-show="isOpened">
      <div class="dropdown-item">
        <div class="custom-control custom-radio">
          <input
            type="radio"
            id="officialPartnerFalse"
            v-model="isOfficialPartner"
            :value="false"
            class="custom-control-input"
            :disabled="isLoading"
          />
          <label class="custom-control-label" for="officialPartnerFalse">{{
            $t('general.all')
          }}</label>
        </div>
      </div>
      <div class="dropdown-item">
        <div class="custom-control custom-radio">
          <input
            type="radio"
            id="officialPartnerTrue"
            v-model="isOfficialPartner"
            :value="true"
            class="custom-control-input"
            :disabled="isLoading"
          />
          <label class="custom-control-label" for="officialPartnerTrue">{{
            $t('general.officialPartner')
          }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SearchMixin from '@/mixins/searchMixin';

export default {
  mixins: [SearchMixin],
  data: () => ({
    isOpened: true,
    fromPage: 'SEARCH',
    isMobileFilter: false,
  }),
  computed: {
    ...mapState({
      isLoading: (state) => state.v2.search.isLoading,
    }),
    isOfficialPartner: {
      get() {
        return this.$store.state.v2.search.isOfficialPartner;
      },
      set(val) {
        this.onOfficialPartnerClick(val);
      },
    },
  },
  methods: {
    async onOfficialPartnerClick(isOfficialPartner) {
      if (this.fromPage === 'HOME') {
        this.$store.commit('v2/search/SET_IS_OFFICIAL_PARTNER', isOfficialPartner);
      } else {
        let query = await this.$store.dispatch('v2/search/setIsOfficialPartner', {
          payload: isOfficialPartner,
          query: this.$route.query,
        });

        await this.updateRoute(query);
      }
    },
    open() {
      this.isOpened = !this.isOpened;
    },
    onUpdateFilter() {},
  },
};
</script>
