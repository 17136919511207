<template>
  <section class="search--filters">
    <client-only>
      <search-profile v-if="$route.name === 'search-user-listing' && $mq !== 'xs'"></search-profile>
      <div class="title--text">
        <div class="back--button" v-if="$mq === 'xs'" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        <div class="d-flex justify-space-between align-center flex-grow-1">
          <h2>{{ $t('general.filter.filter') }}</h2>
          <div class="clear--filter" v-if="$mq === 'xs'" @click="clearFilters()">
            {{ $t('general.filter.clearAll') }}
          </div>
        </div>
      </div>
      <div class="filter--footer" :class="{ active: isActive }" v-if="$mq === 'xs'">
        <button class="btn btn-primary" @click="applyFilters()">
          {{ $t('general.filter.apply') }}
        </button>
      </div>
    </client-only>
    <!--    <listing-type v-show="$mq === 'xs' && !isOfficialPartnerArea"></listing-type>-->
    <property-type-dropdown v-if="$mq === 'xs'"></property-type-dropdown>
    <city-dropdown v-if="isOfficialPartner !== true"></city-dropdown>
    <!--    <project-partner v-show="listingType === 'S' && !isOfficialPartnerArea"></project-partner>-->
    <official-partner v-if="!isOfficialPartnerArea"></official-partner>
    <!--    <range-price v-show="listingType === 'R' && isOfficialPartner !== true"></range-price>-->
    <rent-period
      v-show="listingType === 'R' && !isOfficialPartnerArea && isOfficialPartner !== true"
    ></rent-period>
    <rent-price
      v-show="listingType === 'R' && isOfficialPartner !== true && rentPeriod && rentPeriod.id"
    ></rent-price>
    <sell-price v-show="listingType === 'S' && isOfficialPartner !== true"></sell-price>
    <rent-type v-show="listingType === 'R' && isOfficialPartner !== true"></rent-type>
    <!--    <category-dropdown-->
    <!--      v-show="searchType === 2 && !isOfficialPartnerArea && isOfficialPartner !== true"-->
    <!--    ></category-dropdown>-->
    <building-size v-show="searchType === 2 && isOfficialPartner !== true"></building-size>
    <project-status-dropdown
      v-show="searchType === 1 && isOfficialPartner !== true"
    ></project-status-dropdown>
    <pano-dropdown v-show="searchType === 2 && isOfficialPartner !== true"></pano-dropdown>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import ProjectStatusDropdown from './partials/filters-sidebar/project-status-dropdown';
import RentPrice from './partials/filters-sidebar/rent-price';
import SellPrice from './partials/filters-sidebar/sell-price';
import BuildingSize from './partials/filters-sidebar/building-size';
// import RangePrice from './partials/filters-sidebar/range-price';
import RentPeriod from './partials/filters-sidebar/rent-period';
import RentType from './partials/filters-sidebar/rent-type';
// import CategoryDropdown from './partials/filters-sidebar/category-dropdown';
import PanoDropdown from './partials/filters-sidebar/pano-dropdown';
// import ProjectPartner from './partials/filters-sidebar/project-partner';
// import ListingType from './partials/filters-sidebar/listing-type-dropdown';
import OfficialPartner from './partials/filters-sidebar/official-partner-dropdown';
import CityDropdown from '@/components/search/partials/filters-sidebar/city-dropdown';
import PropertyTypeDropdown from '@/components/search/partials/filters-sidebar/property-type-dropdown';
const SearchProfile = () => import('@/components/search/search-profile');
export default {
  name: 'search-filters',
  components: {
    PanoDropdown,
    // CategoryDropdown,
    RentType,
    RentPeriod,
    // RangePrice,
    BuildingSize,
    RentPrice,
    SellPrice,
    ProjectStatusDropdown,
    // ProjectPartner,
    // ListingType,
    OfficialPartner,
    CityDropdown,
    PropertyTypeDropdown,
    SearchProfile,
  },
  computed: {
    ...mapState({
      listingType: (state) => state.v2.search.listingType,
      searchType: (state) => state.v2.search.searchType,
      isActive: (state) => state.v2.search.activeFilterSidebar,
      isOfficialPartner: (state) => state.v2.search.isOfficialPartner,
      rentPeriod: (state) => state.v2.search.rentPeriod,
    }),
    isOfficialPartnerArea() {
      return this.$route.params.areaUrl != null;
    },
  },
  methods: {
    close() {
      this.refreshPage();
      this.$store.commit('v2/search/SET_ACTIVE_FILTER_SIDEBAR', false);
    },
    clearFilters() {
      this.$store.commit('v2/search/CLEAR_FILTER');
    },
    applyFilters() {
      this.close();
      this.refreshPage();
    },
    refreshPage() {
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
  },
};
</script>
