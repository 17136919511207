<template>
  <div class="search-sidebar--dropdown">
    <div class="d-flex" style="justify-content: space-between" @click="open">
      <h3 class="filter--title">{{ $t('general.filter.titleStatus') }}</h3>
      <i :class="!isOpened ? 'ion-chevron-down' : 'ion-chevron-up'"></i>
    </div>
    <div v-show="isOpened">
      <div class="dropdown-item" v-for="(projectStatus, key) in projectStatuses" :key="key">
        <div class="custom-control custom-radio">
          <input
            type="radio"
            :id="'projectStatus' + key"
            v-model="projectStatusId"
            :value="projectStatus.id"
            class="custom-control-input"
            :disabled="isLoading"
          />
          <label class="custom-control-label" :for="'projectStatus' + key">{{
            $t('general.filter.' + projectStatus.name)
          }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SearchMixin from '@/mixins/searchMixin';

export default {
  mixins: [SearchMixin],
  data: () => ({
    isOpened: true,
    fromPage: 'SEARCH',
    isMobileFilter: false,
  }),
  computed: {
    ...mapState({
      projectStatuses: (state) => state.v2.search.projectStatuses,
      isLoading: (state) => state.v2.search.isLoading,
    }),
    projectStatusId: {
      get() {
        return this.projectStatus ? (this.projectStatus.id ? this.projectStatus.id : 1) : 1;
      },
      set(val) {
        this.onProjectStatusClick(val);
      },
    },
  },
  methods: {
    async onProjectStatusClick(projectStatusId) {
      if (this.fromPage === 'HOME') {
        this.$store.commit('v2/search/SET_SELECTED_PROJECT_STATUS_ID', projectStatusId);
      } else {
        let query = await this.$store.dispatch('v2/search/setSelectedProjectStatusId', {
          payload: projectStatusId,
          query: this.$route.query,
        });

        await this.updateRoute(query);
      }
    },
    open() {
      this.isOpened = !this.isOpened;
    },
    onUpdateFilter() {},
  },
};
</script>
