<template>
  <v-select
    v-model="activePropertyTypes"
    @input="inputFilterHandler"
    :items="propertyTypes"
    :item-text="propertyTypeLabel"
    item-value="id"
    :label="$t('general.allPropertyTypes')"
    :menu-props="{
      'content-class': 'property-type--popup-container',
    }"
    class="basic--dropdown black--label"
    hide-details
    single-line
    solo
    multiple
    return-object
  >
    <template v-slot:selection="{ item, index }">
      <div v-if="activePropertyTypes && index === 0">
        <span v-if="activePropertyTypes.length === 0">
          {{ $t('general.allPropertyTypes') }}
        </span>
        <span v-else>
          {{ `${activePropertyTypes.length} ${$t('general.search.propertyTypesSelected')}` }}
        </span>
      </div>
    </template>
    <template v-slot:prepend-item>
      <div class="d-flex justify-space-between align-center px-4 mb-4 mt-2">
        <div class="menu--title">{{ $t('general.propertyType') }}</div>
        <div class="d-flex align-center">
          <button class="btn small--btn btn--cta-outline" @click="unselectAll">
            {{ $t('general.filter.clearAll') }}
          </button>
          <button
            class="btn small--btn btn-primary ml-2"
            @click="applyFilterFunction"
            v-if="applyFilterFunction"
          >
            {{ $t('general.filter.applyFilter') }}
          </button>
        </div>
      </div>
      <div class="d-flex switch flex-wrap pl-4">
        <div
          class="btn small--btn mr-2 mb-2"
          :class="
            activePropertyCategory && activePropertyCategory.id === category.id
              ? 'btn-primary'
              : 'btn-primary--outline'
          "
          @click="chooseCategory(category)"
          v-for="(category, index) in categories"
          :key="`category-${index}`"
        >
          {{ category ? $t(`general.${category.name}`) : '' }}
        </div>
      </div>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'property-type-dropdown',
  components: {},
  props: ['applyFilterFunction'],
  data: () => ({}),
  computed: {
    ...mapState({
      propertyTypeId: (state) => state.v2.search.propertyTypeId,
      listingType: (state) => state.v2.search.listingType,
      listingTypes: (state) => state.global.listingTypes,
      isOfficialPartner: (state) => state.v2.search.isOfficialPartner,
      categories: (state) => state.v2.search.categories,
      propertyTypes: (state) => state.v2.masters.propertyTypes,
    }),
    ...mapGetters({
      activePropertyType: 'v2/search/activePropertyType',
    }),
    isOfficialPartnerArea() {
      return this.$route.params.areaUrl != null;
    },
    activePropertyCategory: {
      get() {
        return this.$store.state.v2.search.category;
      },
      set(val) {
        this.$store.commit('v2/search/SET_CATEGORY', val);
        this.$store.commit('v2/search/SET_SELECTED_CATEGORY_ID', val != null ? val.id : null);
        this.onPropertyTypeCategoryChange(val);
      },
    },
    activePropertyTypes: {
      get() {
        return this.$store.state.v2.search.activePropertyTypes;
      },
      set(val) {
        this.$store.commit('v2/search/SET_ACTIVE_PROPERTY_TYPES', val);
      },
    },
  },
  methods: {
    propertyTypeLabel(value) {
      return value.name === 'general.all' ? this.$i18n.t(`${value.name}`) : value.name;
    },
    inputFilterHandler() {
      this.$store.commit('v2/search/SET_PAGE', 1);
    },
    async onPropertyTypeCategoryChange(val) {
      let allPromises = [];
      let categoryId = val ? val.id : null;
      allPromises.push(this.$store.dispatch('v2/masters/getPropertyTypesByCategory', categoryId));
      await Promise.all(allPromises);
      this.activePropertyTypes = this.propertyTypes.slice();
    },
    chooseCategory(category) {
      this.inputFilterHandler();
      this.activePropertyCategory = category;
    },
    unselectAll() {
      this.activePropertyTypes = [];
    },
  },
};
</script>

<style scoped></style>
